.App {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.Chat .list {
  padding: 5px 0;
  overflow: scroll;
}

.Share.-desktop {
  height: 100%;
}

.Share .ant-space {
  width: 100%;
}

.Speaker {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Speaker .content {
  height: 89vh;
  display: flex;
}

.Speaker .content .ant-col {
  padding: 0px 5px;
}

.Speaker #camera {
  height: 100%;
}

.Speaker .content .Chat .list {
  height: 74vh;
}

.Stream {
  height: 100%;
  display: flex;
}

.Viewer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Viewer .Chat {
  height: 100%;
}

.Viewer .content {
  display: flex;
  height: 100%;
}

.Viewer .content .left-block {
  flex: 6;
  height: 100%;
}

.Viewer .content .left-block .stream {
  height: 100%;
  display: flex;
}

.Viewer .content .left-block .stream video {
  max-height: 83vh !important;
}

.Viewer .content .left-block .stream .camera.in-window {
  width: 20%;
  z-index: 9999;
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
}

.Viewer .content .right-block #chat {
  height: calc(100vh - 217px);
}

.Viewer .content .right-block {
  flex: 3;
  margin-left: 5px;
}

@media only screen and (max-width: 600px) {
  .Viewer .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .Viewer .content .right-block #chat {
    height: calc(80vh);
  }

  .Viewer .ant-card-body {
    padding: 10px 5px;
  }

  .Viewer .ant-card {
    zoom: 2;
  }

  .Viewer .Chat {
    height: 100%;
    zoom: 2;
  }

  .Viewer .content .right-block {
    flex: 1;
    margin-left: 0px;
    margin-top: 20px;
  }
}

.Account {
  width: 1000px;
  display: flex;
  margin: 30px 0;
}

.Account .ant-typography {
  margin-bottom: 0 !important;
}

.Account .create_webinar {
  margin-bottom: 40px;
}

.Landing {
  width: 1000px;
  display: flex;
  margin: 30px 0;
}

.Landing .ant-space {
  width: 100%;
}

.Landing .ant-space-item {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Landing .ant-typography {
  margin-bottom: 0 !important;
}

.Landing .ant-typography-ellipsis {
  white-space: break-spaces;
}

.Landing .ant-descriptions {
  width: 100%;
}

.Landing .ant-descriptions-item-label {
  width: 25%;
}

.Landing .ant-row {
  width: 100%
}

.Landing .buttons {
  margin-bottom: 40px;
}

.Empty {
  align-self: center;
}

.Spinner {
  align-self: center;
}

.Form {
  width: 1000px;
  display: flex;
  margin: 30px 0;
}

.Form .ant-space {
  width: 100%;
}

.Form .ant-picker {
  width: 100%;
}

.Form .ant-form {
  width: 100%;
}

.Form .ant-space-item {
  width: 100%;
  display: flex;
  justify-content: center;
}

