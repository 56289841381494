@import "./styles/ktz/ktz.less";

html {
  height: 100vh;
  width: 100vw;
}

body {
  width: 100%;
  height: 100%;
  min-width: 1150px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: auto   !important;
  max-height: 70vh;
}

.ant-statistic {
  display: flex;
  align-items: center;
}

.ant-statistic-title {
  font-size: 18px;
  margin-right: 5px;
  margin-bottom: 0;
}

.ant-typography {
  margin-bottom: 5px !important;
}

.ant-comment-inner {
  padding-bottom: 0px;
}

.ant-card-body {
  width: 100%;
}

