@font-face {
  font-family: 'CirceRounded';
  src: url('../../CirceRounded/circe-rounded-regular.eot');
  src:
    url('../../CirceRounded/circe-rounded-regular.eot?#iefix') format('embedded-opentype'),
    url('../../CirceRounded/circe-rounded-regular.woff') format('woff'),
    url('../../CirceRounded/circe-rounded-regular.ttf') format('truetype'),
    url('../../CirceRounded/circe-rounded-regular.svg#circe-rounded-regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: CirceRounded, Tahoma, sans-serif !important;
}

// Шапка
.Header {
  margin: -5px 0 !important;

  h3.ant-typography {
    color: #414243 !important;
    font-size: 28px !important;
  }
}

// Чат
.Chat {
  // Сообщение чата
  .ant-comment-inner {
    padding-bottom: 0 !important;
  }

  // Иконка отправки сообщения
  .ant-input-group-addon {
    left: 5px !important;
    color: #aaa !important;
    border: none !important;
    font-size: 22px !important;
    background: none !important;
  }

  // Тумблер переключения режимов чата
  .ant-radio-group {

    .ant-radio-button-wrapper {
      transition: none;
      &:hover {
        color: #414243 !important
      }
    }

    .ant-radio-button-wrapper-checked:hover {
      color: white !important;
    }

    .ant-radio-button-wrapper-checked {
      border-bottom-color:#B3B7BE!important;
      border-bottom-left-radius:0px!important;
      border-bottom-right-radius:3px!important;
      border-bottom-style:solid!important;
      border-bottom-width:1px!important;
      border-image-outset:0!important;
      border-image-repeat:stretch!important;
      border-image-slice:100%!important;
      border-image-source:none!important;
      border-image-width:1!important;
      border-left-color:rgb(255, 255, 255)!important;
      border-left-style:none!important;
      border-left-width:0px!important;
      border-right-color:#B3B7BE!important;
      border-right-style:solid!important;
      border-right-width:1px!important;
      border-top-color:#B3B7BE!important;
      border-top-left-radius:0px!important;
      border-top-right-radius:3px!important;
      border-top-style:solid!important;
      border-top-width:1.01562px!important;
      box-shadow: none !important;
      background: #B3B7BE !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #ffffff00 !important;
    }

    label:first-child {
      border-radius: 0 !important;
    }

    label:last-child {
      border-left: none !important;
      border-radius: 0 !important;
    }

    span:last-child {
      label {
        border-radius: 0 !important;
      }
    }
  }

}

// Стили выгружены из Chrome
.ant-btn {
  align-items:center!important;
  background-color:rgb(245, 248, 250)!important;
  background-image:linear-gradient(rgb(255, 223, 93), rgb(247, 198, 43))!important;
  border-bottom-color:rgb(255, 255, 255)!important;
  border-bottom-left-radius:62px!important;
  border-bottom-right-radius:62px!important;
  border-bottom-style:none!important;
  border-bottom-width:0px!important;
  border-image-outset:0!important;
  border-image-repeat:stretch!important;
  border-image-slice:100%!important;
  border-image-source:none!important;
  border-image-width:1!important;
  border-left-color:rgb(255, 255, 255)!important;
  border-left-style:none!important;
  border-left-width:0px!important;
  border-right-color:rgb(255, 255, 255)!important;
  border-right-style:none!important;
  border-right-width:0px!important;
  border-top-color:rgb(255, 255, 255)!important;
  border-top-left-radius:62px!important;
  border-top-right-radius:62px!important;
  border-top-style:none!important;
  border-top-width:0px!important;
  box-shadow:rgba(255, 255, 255, 0.27) 0px 0px 8px 0px inset, rgba(227, 163, 13, 0.62) 0px 16px 32px -12px!important;
  box-sizing:border-box!important;
  color:rgb(255, 255, 255)!important;
  cursor:pointer!important;
  display:flex!important;
  flex-direction:row!important;
  font-size:15px!important;
  font-weight:400!important;
  height:38px!important;
  justify-content:center!important;
  letter-spacing:normal!important;
  line-height:22.5px!important;
  margin-bottom:0px!important;
  margin-left:0px!important;
  margin-right:0px!important;
  margin-top:0px!important;
  min-height:30px!important;
  min-width:160px!important;
  outline-color:rgb(255, 255, 255)!important;
  outline-style:none!important;
  outline-width:0px!important;
  padding-bottom:5px!important;
  padding-left:20px!important;
  padding-right:20px!important;
  padding-top:5px!important;
  text-align:left!important;
  text-shadow:none!important;
  text-transform:none!important;
  transition-duration:0.25s!important;
  transition-property:background-image, box-shadow!important;
  transition-timing-function:ease-in-out!important;
  vertical-align:middle!important;
  -webkit-box-align:center!important;
  -webkit-box-direction:normal!important;
  -webkit-box-orient:horizontal!important;
  -webkit-box-pack:center!important;
  -webkit-font-smoothing:antialiased!important;
}

// Стили выгружены из Chrome
.ant-input {
  font-family:CirceRounded, Tahoma, sans-serif!important;
  appearance:none!important;
  background-attachment:scroll!important;
  background-clip:border-box!important;
  background-color:rgb(255, 255, 255)!important;
  background-image:none!important;
  background-origin:padding-box!important;
  background-position-x:0%!important;
  background-position-y:0%!important;
  background-size:auto!important;
  border-bottom-color:rgb(24, 32, 38)!important;
  border-bottom-left-radius:3px!important;
  border-bottom-right-radius:3px!important;
  border-bottom-style:none!important;
  border-bottom-width:0px!important;
  border-image-outset:0!important;
  border-image-repeat:stretch!important;
  border-image-slice:100%!important;
  border-image-source:none!important;
  border-image-width:1!important;
  border-left-color:rgb(24, 32, 38)!important;
  border-left-style:none!important;
  border-left-width:0px!important;
  border-right-color:rgb(24, 32, 38)!important;
  border-right-style:none!important;
  border-right-width:0px!important;
  border-top-color:rgb(24, 32, 38)!important;
  border-top-left-radius:3px!important;
  border-top-right-radius:3px!important;
  border-top-style:none!important;
  border-top-width:0px!important;
  box-shadow:rgba(232, 186, 21, 0.035) 0px 0px 0px 0.0346909px, rgba(195, 135, 35, 0.008) 0px 0px 0px 0.104073px, rgba(16, 22, 26, 0.15) 0px 0.0346909px 0.0346909px 0.965309px inset, rgba(16, 22, 26, 0.192) 0px 0.965309px 0.965309px 0px inset!important;
  box-sizing:border-box!important;
  color:rgb(24, 32, 38)!important;
  cursor:text!important;
  display:inline-block!important;
  font-size:15px!important;
  font-stretch:100%!important;
  font-style:normal!important;
  font-variant-caps:normal!important;
  font-variant-east-asian:normal!important;
  font-variant-ligatures:normal!important;
  font-variant-numeric:normal!important;
  font-weight:400!important;
  letter-spacing:normal!important;
  line-height:40px!important;
  margin-bottom:0px!important;
  margin-left:0px!important;
  margin-right:0px!important;
  margin-top:0px!important;
  outline-color:rgb(24, 32, 38)!important;
  outline-style:none!important;
  outline-width:0px!important;
  padding-bottom:0px!important;
  padding-left:20px!important;
  padding-right:20px!important;
  padding-top:0px!important;
  position:relative!important;
  text-align:start!important;
  text-indent:0px!important;
  text-rendering:auto!important;
  text-shadow:none!important;
  text-transform:none!important;
  transition-delay:0s, 0s!important;
  transition-duration:0.1s, 0.1s!important;
  transition-property:box-shadow, -webkit-box-shadow!important;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9), cubic-bezier(0.4, 1, 0.75, 0.9)!important;
  unicode-bidi:isolate!important;
  vertical-align:middle!important;
  word-spacing:0px!important;
  writing-mode:horizontal-tb!important;
  -webkit-box-direction:normal!important;
  -webkit-font-smoothing:antialiased!important;
  -webkit-rtl-ordering:logical!important;
  border-image:none!important;
  -webkit-border-image:none!important;

  &:focus {
    box-shadow:0 0 0 1px #e8ba15, 0 0 0 3px rgba(195,135,35,0.19), inset 0 1px 1px rgba(16,22,26,0.15)!important;
  }
}
